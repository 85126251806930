<template>
  <b-modal
    ref="modalFiltroCustom"
    size="xl"
    no-close-on-backdrop
    :hide-header-close="true"
  >
    <b-row style="margin-bottom: 5%;margin-top: 1%;">
      <b-col cols="3">
        <b-form-group
          label="Nome Filtro"
        >
          <v-select
            id="nomeFiltroInput"
            :ref="`SelectFiltros`"
            v-model="filtroSelect"
            v-b-tooltip.hover
            :options="opcoesFiltrosCustom"
            :clearable="true"
            label="nome"
            deselect-from-dropdown
            placeholder="Filtros"
            @input="mudarDocs($event.filtrosDocs)"
          >
            <template
              #option="{ nome, cor }"
            >
              <b-badge
                pill
                :style="`background-color:${cor};`"
              >
                {{ nome }}
              </b-badge>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        id="filtroColor"
        cols="1"
        style="margin-top: 30px;"
        @click="$refs.colorSelect.onShow()"
      >
        <feather-icon
          v-b-tooltip.hover
          title="Novo"
          icon="PlusIcon"
          class="text-secondary"
          size="30"
        />
      </b-col>
      <b-col cols="3">
        <small
          class="text-danger alinhar-cifrao"
          style="left: 78px;"
        >*</small>
        <b-form-group
          label="Usuários"
        >
          <v-select
            :ref="`SelectUsers`"
            v-model="filtroSelect.usuarios"
            v-b-tooltip.hover
            :options="usuarios"
            :clearable="true"
            label="nome"
            :disabled="!filtroSelect.nome || filtroSelect.nome == ''"
            multiple
            deselect-from-dropdown
            placeholder="Usuários"
          />
          <small
            v-if="filtroSelect.filtrosDocs && !filtroSelect.usuarios.length > 0"
            class="text-danger"
          >Usuários é obrigatório
          </small>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <small
          class="text-danger alinhar-cifrao"
          style="left: 170px;"
        >*</small>
        <b-form-group
          label="Tipos de documentos"
        >
          <v-select
            :ref="`SelectDocs`"
            v-model="filtroSelect.filtrosDocs"
            v-b-tooltip.hover
            :options="filtrosRapidosDocs"
            :clearable="true"
            :disabled="!filtroSelect.nome || filtroSelect.nome == ''"
            multiple
            deselect-from-dropdown
            placeholder="Documentos"
            @input="mudarDocs($event)"
          />
          <small
            v-if="filtroSelect.filtrosDocs && !filtroSelect.filtrosDocs.length > 0"
            class="text-danger"
          >Tipos de documentos é obrigatório
          </small>
        </b-form-group>
      </b-col>
      <b-col
        cols="1"
        style="display: flex;align-items: center;"
      >
        <feather-icon
          icon="TrashIcon"
          class="text-danger"
          size="20"
          @click.stop="remover()"
        />
      </b-col>
    </b-row>

    <template #modal-title>
      <h3>Cadastrar filtros especiais</h3>
      <fieldset class="font-weight-bold text-muted small">
        Crie filtros personalizados, informando usuário, tipo de documento e empresa para cada filtro.
      </fieldset>
    </template>
    <template #modal-footer>
      <b-btn
        variant="none"
        size="sm"
        class="btn-primary btn-none"
        style="margin-left: 0.3rem;"
        :disabled="desabledSalvar"
        @click="salvar"
      >
        Salvar
      </b-btn>
      <b-btn
        variant="none"
        size="sm"
        class="btn-secondary btn-none"
        style="margin-left: 0.3rem;"
        @click="cancelar()"
      >
        Cancelar
      </b-btn>
    </template>
    <empresas-vinculadas
      ref="empresas-vinculadas"
      v-model="filtroSelect.empresas"
    />
    <color-select
      ref="colorSelect"
      v-model="filtroSelect"
      @criar="criarFiltro($event)"
    />
  </b-modal>
</template>

<style lang="scss">
.v-select:not(.vs--single) .vs__selected {
    font-size: 0.8rem;
}
.alinhar-cifrao {
  position: absolute;
  top: auto;
}

</style>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Vue from 'vue'
import api from '../../services/api'

export default {
  name: 'FiltroCustom',
  components: {
    EmpresasVinculadas: () => import('./shared/components/vinculo-empresas/VinculoEmpresas.vue'),
    ColorSelect: () => import('./shared/components/color-select-filtro/ColorSelectFiltro.vue'),
    vSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    filtrosDefaultDocs: {
      type: Array,
      default: null,
    },
    opcoesFiltrosCustom: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      desabledSalvar: false,
      filtrosRapidosDocs: this.filtrosDefaultDocs,
      iconCreate: true,
      usuarios: [],
      empresas: [],
      filtroSelect: {},
    }
  },
  watch: {
    value(value, oldValue) {
      if (value !== oldValue) {
        this.filtroSelect = value
      }
    },
  },
  created() {
    this.carregarUsuarios()
    this.carregarEmpresas()
  },
  methods: {
    mudarDocs(docs) {
      if (docs && docs.find(find => find === 'Todos')) {
        this.filtrosRapidosDocs = ['Todos']
      } else {
        this.filtrosRapidosDocs = this.filtrosDefaultDocs
      }
    },
    cancelar() {
      this.$emit('cancelar')
      this.filtroSelect = {}
      this.$refs.modalFiltroCustom.hide()
    },
    carregarUsuarios() {
      api.getUsuariosFiltro()
        .then(payload => {
          if (payload) {
            this.usuarios = payload.data
          }
        })
    },
    carregarEmpresas() {
      api.getEmpresasFiltro()
        .then(payload => {
          if (payload) {
            this.empresas = payload.data
          }
        })
    },
    remover() {
      if (!this.filtroSelect.id > 0) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Filtro inválido',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
      } else {
        Vue.swal({
          title: 'Remover?',
          text: 'Tem certeza que deseja remover esse filtro?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          customClass: {
            confirmButton: 'btn btn-outline-primary mr-50',
            cancelButton: 'btn btn-outline-warning ',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              api.deleteFiltroCustom(this.filtroSelect.id)
                .then(() => {
                  Vue.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Removido',
                      icon: 'fa-solid fa-circle-check',
                      variant: 'success',
                    },
                  })
                  this.opcoesFiltrosCustom.splice(this.opcoesFiltrosCustom.findIndex(filtro => filtro.id === this.filtroSelect.id), 1)
                  this.filtroSelect = { empresas: { naoVinculadas: [], vinculadas: [] } }
                })
                .catch(err => {
                  Vue.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Não foi possível remover as informações',
                      icon: 'fa-solid fa-circle-exclamation',
                      variant: 'danger',
                    },
                  })
                  throw err
                })
            }
          })
      }
    },
    criarFiltro(filtro) {
      const filtroNew = filtro
      filtroNew.empresas.naoVinculadas = this.empresas
      this.opcoesFiltrosCustom.push(filtroNew)
      this.filtroSelect = filtroNew
    },
    show() {
      this.desabledSalvar = false
      this.$refs.modalFiltroCustom.show()
    },
    hide() {
      this.$refs.modalFiltroCustom.hide()
    },
    salvar() {
      if (this.filtroSelect && this.filtroSelect.nome && this.filtroSelect.usuarios.length > 0 && this.filtroSelect.filtrosDocs.length > 0) {
        this.desabledSalvar = true
        api.salvarFiltroCustom(this.opcoesFiltrosCustom)
          .then(payload => {
            this.opcoesFiltrosCustom = payload.data
            this.value = {}
            this.hide()
          })
          .finally(() => {
            this.desabledSalvar = false
          })
      } else {
        this.$erro('Preencha os campos corretamente', 'danger')
      }
    },
  },
}
</script>
